import axios from 'axios'
import apiDomain from '@/config/api.js'
import externalComponent from '@/utils/external-component.js'
import { EventBus } from '@/assets/static/event-bus.js'

// import ModalCreateProperty from '@/components/modals/modalCreateProperty'
// import ModalCreateStay from '@/components/modals/modalCreateStay'

const Card = () => externalComponent('card')
const CardIcon = () => externalComponent('cardIcon')
const CardButton = () => externalComponent('cardButton')
const Toolbar = () => externalComponent('toolbar')
const Table = () => externalComponent('table')
const Pagination = () => externalComponent('pagination')

export default {
  name: 'Dashboard',

  components: {
    Card,
    CardIcon,
    CardButton,
    Toolbar,
    Table,
    Pagination,
    // ModalCreateProperty,
    // ModalCreateStay
  },

  data() {
    return {
      resume: {
        charges: 0,
        invoices: 0,
      },

      movements: {
        action: {
          name: 'Acciones',
          options: [
            {
              name: 'Agregar Dashboard',
              value: 'addProperty'
            },
            {
              name: 'Agregar Estancia',
              value: 'addStay'
            }
          ]
        },
        search: {
          name: 'Buscar',
          placeholder: ''
        },
        filter: {
          name: 'Estado',
          options: [
            {
              name: 'Compañía',
              value: 'companie'
            }
          ]
        },
        columns: [
          {
            name: '#'
          },
          {
            name: 'Fecha'
          },
          {
            name: 'Compañía'
          },
          {
            name: 'Tipo'
          },
          {
            name: 'Monto'
          },
          {
            name: 'Estado'
          }
        ],
        rows: [],
        pages: 0,
        currentPage: 1
      },

      isModalCreateProperty: false,
      isModalCreateStay: false
    }
  },

  methods: {
    getResume: async function() {return
      let response;

      await axios
        .get(apiDomain + '/commercial/dashboard/resume')
        .then(res => response = res)
        .catch(err => error = true);

      if (typeof response !== 'undefined') {
        this.resume = response.data;
      } else {
        this.$toast.error('Error en la consulta del resumen');
      }
    },

    getMovements: async function() {return
      let response;

      await axios
        .get(apiDomain + '/commercial/dashboard/movements/' + this.movements.currentPage)
        .then(res => response = res)
        .catch(err => error = true);

      if (typeof response !== 'undefined' && response.status === 200) {
        this.movements.rows = response.data.rows;
        this.movements.pages = response.data.pages;
      } else {
        this.$toast.error('Error en la consulta de los movimientos');
      }
    },

    doAction: function(action) {
      switch (action) {
        case 'addProperty':
          this.showModalCreateProperty();
          break;
        case 'addStay':
          this.showModalCreateStay();
          break;
      }
    },

    searchMovements: function() {
      // console.log('Busca');
    },

    orderMovements: function() {

    },

    showModalCreateProperty: function() {
      this.isModalCreateProperty = true;
    },

    hideModalCreateProperty: function() {
      this.isModalCreateProperty = false;
    },

    showModalCreateStay: function() {
      this.isModalCreateStay = true;
    },

    hideModalCreateStay: function() {
      this.isModalCreateStay = false;
    }
  },

  mounted() {
    this.getResume();
    this.getMovements();

    EventBus.$on('hideModalCreateProperty', () => {
      this.hideModalCreateProperty();
    });

    EventBus.$on('hideModalCreateStay', () => {
      this.hideModalCreateStay();
    });
  },

  watch: {
    'movements.currentPage': function(val) {
      this.getMovements();
    }
  }
}
